export const INSTRUCTIONS_PIX = [
  '<p>Abra o seu aplicativo da instituição financeira e entre na área Pix.</p>',
  '<p>Selecione a opção <strong>Ler QR Code</strong> e escaneie a imagem acima.</p>',
  '<p>Se preferir, <strong>Copie e Cole o Código Pix</strong> no seu aplicativo financeiro.</p>',
  '<p>Agora é só confirmar as informações e pronto, seu pagamento estará realizado.</p>',
];

export const INSTRUCTIONS_BILLET = [
  '<p>Use o código de barras acima para pagar usando seu internet banking ou dirija-se a um banco, caixa eletrônico ou lotérica com seu boleto impresso.</p>',
  '<p>Se o seu boleto tem a data de vencimento em um fim de semana ou feriado, você poderá pagá-lo normalmente no próximo dia útil.</p>',
  '<p>A confirmação do seu pagamento pode levar até 3 dias úteis.</p>',
];
