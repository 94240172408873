



















import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class BoxPaymentInstruction extends Vue {
  @Prop({ required: true }) instructions!: Array<string>;
}
